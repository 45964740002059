// COMPONENTS:
import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { CustomersType } from "api/customers/types";
import { dateFormatterUTC } from "components/Table/formatters/dateFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { booleanFormatter } from "utils/booleanFormatter/booleanFormatter";

import CustomerField from "./CustomerField";

interface ICustomerOverview {
  customer: CustomersType | undefined;
  isLoading: boolean;
}

const CustomerOverview: FC<ICustomerOverview> = ({ customer, isLoading }) => {
  return (
    <>
      <Box sx={{ backgroundColor: "#EFEFEF", padding: "5px 32px" }}>
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ margin: "5px 0px 16px" }}
        >
          General Information
        </Typography>

        <Grid container columnSpacing={4}>
          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Salutation"
              value={customer?.salutation}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="First Name"
              value={customer?.firstName}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Middle Name"
              value={customer?.middleName}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Last Name"
              value={customer?.lastName}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label={<FormattedMessage id="GLOBAL.ADDRESS" />}
              value={customer?.address}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label={<FormattedMessage id="CUSTOMERS.CITY" />}
              value={customer?.city}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label={<FormattedMessage id="CUSTOMERS.STATE" />}
              value={customer?.state}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label={<FormattedMessage id="CUSTOMERS.ZIP" />}
              value={customer?.zip}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Email"
              value={customer?.email}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Birthday"
              value={dateFormatterUTC(customer?.birthday)}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Phone"
              value={`+1 ${customer?.phone}`}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Mobilephone"
              value={`+1 ${customer?.mobilePhone}`}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Associate Brand"
              value={customer?.associatedBrand}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Referral Source"
              value={customer?.referralSource}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Lead Source"
              value={customer?.leadSource}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Franchise Choice"
              value={customer?.franchiseChoice}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Driver's License Number"
              value={customer?.personalInfo?.driversLicenseId}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Driver's License Issuance Date"
              value={dateFormatterUTC(
                customer?.personalInfo?.driversLicenseIssueDate,
              )}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Driver's License Expiration Date"
              value={dateFormatterUTC(
                customer?.personalInfo?.driversLicenseExpireDate,
              )}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}></Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Marital Status"
              value={customer?.personalInfo?.maritalStatus}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label={<FormattedMessage id="CUSTOMERS.SPOUSE" />}
              value={
                customer?.spouse
                  ? `${customer.spouse.firstName} ${customer.spouse.lastName}`
                  : "-"
              }
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Military Status"
              value={customer?.personalInfo?.militaryStatus}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Military Affiliation"
              value={customer?.personalInfo?.militaryAffiliation}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label={<FormattedMessage id="CUSTOMERS.SOCIAL" />}
              value={customer?.social}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Submission Email"
              value={customer?.submissionEmail}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Submission Password"
              value={customer?.submissionPassword}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: "#fff", padding: "5px 32px" }}>
        {/* PERSONAL INFORMATION */}
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ margin: "5px 0px 16px" }}
        >
          <FormattedMessage id="CUSTOMERS.PERSONAL_INFO" />
        </Typography>
        <Grid container columnSpacing={4}>
          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Member of Credit Union"
              value={customer?.personalInfo?.creditUnion}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Business Banking Relationship"
              value={customer?.personalInfo?.businessBank}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Has Credit Repair Before"
              value={booleanFormatter(
                customer?.personalInfo?.creditRepairBefore,
              )}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="BK in past 20 years"
              value={customer?.personalInfo?.judgementsLiensBankruptcy}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Personal Banking Relationship"
              value={customer?.personalInfo?.personalBank}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Business Account Routing Number"
              value={customer?.personalInfo?.businessBankRoutingNumber}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Worked with a Finance Company"
              value={booleanFormatter(
                customer?.personalInfo?.previoiusFinanceCompany,
              )}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Number of Fraud Alert"
              value={customer?.personalInfo?.numberOfFraudAlert}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Bank Account Number"
              value={customer?.personalInfo?.bankAccountNumber}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Business Account Number"
              value={customer?.personalInfo?.businessBankAccountNumber}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Fraud Alert"
              value={booleanFormatter(customer?.personalInfo?.fraudAlert)}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}></Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Bank Routing Number"
              value={customer?.personalInfo?.bankRoutingNumber}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}></Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}></Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: "#EFEFEF", padding: "5px 32px" }}>
        {/* HOUSING INFORMATION */}
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ margin: "5px 0px 16px" }}
        >
          Housing Information
        </Typography>
        <Grid container columnSpacing={4}>
          {/* 1 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Housing Status"
              value={customer?.housingInfo?.houstingStatus}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Monthly Housing Payment"
              value={priceFormatter(
                customer?.housingInfo?.monthlyHousingPayment,
                0,
              )}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Estimated Length of time at Residence"
              value={customer?.housingInfo?.estimatedLengthOfTimeAtResidence}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Move in Date"
              value={dateFormatterUTC(customer?.housingInfo?.moveInDate)}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Calculated Time at Residence"
              value={customer?.housingInfo?.calculatedLengthOfTimeAtResidence}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={4}></Grid>
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: "#fff", padding: "5px 32px" }}>
        {/* EMPLOYMENT INFORMATION */}
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ margin: "5px 0px 16px" }}
        >
          <FormattedMessage id="CUSTOMERS.EMPLOYMENT_INFO" />
        </Typography>
        <Grid container columnSpacing={4}>
          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Income Type"
              value={customer?.employmentInfo?.incomeType}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Employer"
              value={customer?.employmentInfo?.employerName}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Employer Phone Number"
              value={customer?.employmentInfo?.employerPhone}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Employer Address"
              value={customer?.employmentInfo?.employerAddress}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Est. time at job"
              value={customer?.employmentInfo?.estimatedTimeAtJob}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Start Date with Employer"
              value={dateFormatterUTC(
                customer?.employmentInfo?.startDateWithEmployer,
              )}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Calculated Length of Employment"
              value={customer?.employmentInfo?.calculatedLengthOfEmployment}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Occupation/Position"
              value={customer?.employmentInfo?.occupationPosition}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Stated Monthly Income"
              value={priceFormatter(
                customer?.employmentInfo?.statedMonthlyIncome,
                0,
              )}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Stated Annual Income"
              value={priceFormatter(
                customer?.employmentInfo?.statedAnnualIncome,
                0,
              )}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}></Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Stated Annual Household Income"
              value={priceFormatter(
                customer?.employmentInfo?.statedAnnualHouseholdIncome,
                0,
              )}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Monthly Personal Gross Income"
              value={priceFormatter(
                customer?.employmentInfo?.monthlyGrossIncome,
                0,
              )}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Annual Personal Income"
              value={priceFormatter(
                customer?.employmentInfo?.annualPersonalIncome,
                0,
              )}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}></Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Annual Household Income"
              value={priceFormatter(
                customer?.employmentInfo?.totalAnnualHouseholdIncome,
                0,
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: "#EFEFEF", padding: "5px 32px" }}>
        {/* SECURITY QUESTIONS */}
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ margin: "5px 0px 16px" }}
        >
          Security Questions
        </Typography>
        <Grid container columnSpacing={4}>
          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Place of Birth"
              value={customer?.securityQuestions?.birthCity}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Born in foreign country"
              value={
                customer?.securityQuestions?.bronInForeignCountry ? "Yes" : "No"
              }
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Legal Permanent Resident"
              value={
                customer?.securityQuestions?.legalPermanentResident
                  ? "Yes"
                  : "No"
              }
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Green Card Expiration Date"
              value={dateFormatterUTC(
                customer?.securityQuestions?.greenCardExpirationDate,
              )}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Mothers Maiden Name"
              value={customer?.securityQuestions?.mothersMaidenName}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="High School Mascot"
              value={customer?.securityQuestions?.highSchoolMascot}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="High School City"
              value={customer?.securityQuestions?.highSchoolCity}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Name of Street you grew up on"
              value={customer?.securityQuestions?.nameOfStreetYouGrewUp}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Name of Favorite Pet"
              value={customer?.securityQuestions?.favoritePetsName}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}></Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}></Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: "#fff", padding: "5px 32px" }}>
        {/* EDUCATION INFROMATION */}
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ margin: "5px 0px 16px" }}
        >
          <FormattedMessage id="CUSTOMERS.EDUCATION_INFO" />
        </Typography>
        <Grid container columnSpacing={4}>
          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="College/University Attended"
              value={customer?.educationInfo?.collegeAttended}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Field of study"
              value={customer?.educationInfo?.fieldOfStudy}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Degree"
              value={customer?.educationInfo?.degree}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Graduation date"
              value={dateFormatterUTC(customer?.educationInfo?.graduatedDate)}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Graduate School Attended"
              value={customer?.educationInfo?.graduateSchoolAttended}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Graduate School Field of Study"
              value={customer?.educationInfo?.graduateSchoolFieldOfStudy}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Graduate Degree Received"
              value={customer?.educationInfo?.graduateDegreeReceived}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={3}>
            <CustomerField
              isLoading={isLoading}
              label="Graduate Graduation date"
              value={dateFormatterUTC(
                customer?.educationInfo?.graduateGraduationDate,
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: "#EFEFEF", padding: "5px 32px" }}>
        {/* ASSET INFORMATION */}
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ margin: "5px 0px 16px" }}
        >
          <FormattedMessage id="CUSTOMERS.ASSET_INFO" />
        </Typography>
        <Grid container columnSpacing={4}>
          {/* 1 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Combined Checking & Savings Balance"
              value={priceFormatter(
                customer?.assetInfo?.combinedCheckingSavingsBalance,
                0,
              )}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Stocks, Bonds & Mutual Funds"
              value={priceFormatter(
                customer?.assetInfo?.stocksBondsMutualFunds,
                0,
              )}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Retirement Account Balance"
              value={priceFormatter(
                customer?.assetInfo?.retirementAccountBalance,
                0,
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: "#fff", padding: "5px 32px" }}>
        {/* PRIMARY RESIDENCE VALUATION */}
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ margin: "5px 0px 16px" }}
        >
          Primary Residence Valuation
        </Typography>
        <Grid container columnSpacing={4}>
          {/* 1 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="AVM in Response"
              value={priceFormatter(
                customer?.primaryResidenceValuation?.avmValue,
                0,
              )}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Market Value in Response"
              value={priceFormatter(
                customer?.primaryResidenceValuation?.marketValue,
                0,
              )}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Zillow Value"
              value={priceFormatter(
                customer?.primaryResidenceValuation?.zillowValue,
                0,
              )}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Est. Value of Primary Residence"
              value={priceFormatter(
                customer?.primaryResidenceValuation?.estimatedPropertyValue,
                0,
              )}
            />
          </Grid>
          {/* 2 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Est. Equity in Primary Residence"
              value={priceFormatter(
                customer?.primaryResidenceValuation
                  ?.estimatedEquityPrimaryResidence,
                0,
              )}
            />
          </Grid>
          {/* 3 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Cal. Value of Primary Residence"
              value={priceFormatter(
                customer?.primaryResidenceValuation?.calculatedValue,
                0,
              )}
            />
          </Grid>
          {/* 4 */}
          <Grid item xs={12} lg={4}>
            <CustomerField
              isLoading={isLoading}
              label="Cal. Equity of Primary Residence"
              value={priceFormatter(
                customer?.primaryResidenceValuation?.calculatedEquity,
                0,
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CustomerOverview;
